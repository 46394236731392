import React, { useCallback, useEffect } from 'react';
import { CloseCircleFilled } from '@ant-design/icons';
import { CSSTransition } from 'react-transition-group';
import './Modal.css';

function Modal({ show, onClose, children }) {
  const closeOnEscapeKeyDown = useCallback((e) => {
    if ((e.charCode || e.keyCode) === 27) {
      onClose();
    }
  }, [onClose]);
  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
    };
  }, [closeOnEscapeKeyDown]);

  return (
    <CSSTransition
      in={show}
      unmountOnExit
      timeout={{ enter: 0, exit: 100 }}
    >
      <div className="modalContainer" onClick={onClose}>
        <div className="modalContent" onClick={(e) => e.stopPropagation()}>
          <CloseCircleFilled onClick={onClose} />
          {children}
        </div>
      </div>
    </CSSTransition>
  );
}

export default Modal;
