import React from 'react';
import './AdvantageCard.css';

export default function AdvantageCard({ title, text, text2 }) {
  return (
    <div className="advantageCard">
      <h2>{title}</h2>
      <div className="advantageCard__text">
        <p>{text}</p>
        <p style={{ marginTop: '20px' }}>{text2}</p>
      </div>
    </div>
  );
}
