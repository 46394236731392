import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Modal from '../../components/Modal/Modal';
import IndUseCaseCard from './industryUseCase/indUseCaseCard';
import IndustryCard from './card/IndustryCard';
import IndustriesData from './data/IndustriesData';
import './Industries.css';
import 'aos/dist/aos.css';

AOS.init();

function Industries() {
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalInfo, setModalInfo] = useState([]);

  const handleModalInformation = (selectedId) => {
    const selectedItem = IndustriesData.find((item) => item.id === selectedId);
    setSelectedIndustry(selectedItem.title);
    setModalInfo(selectedItem.children);
  };

  const clearData = () => {
    setModalInfo([]);
  };

  useEffect(() => {
    if (modalInfo.length > 0) {
      setShowModal(true);
    }
  }, [modalInfo]);

  useEffect(() => {
    if (showModal === false) {
      clearData();
    }
  }, [showModal]);

  return (
    <div className="sectionNavMargin whiteFont">
      <div id="contentBlock" className="section__padding">
        <div className="sectionColumnContainer textCenter">
          <span
            className="pageTitle"
            data-aos="fade-up"
            data-aos-offset="100"
            data-aos-delay="50"
            data-aos-duration="600"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
          >
            Industrias
          </span>
          <span
            className="sectionDescription industriesDesc"
            data-aos="fade-zoom-in"
            data-aos-offset="100"
            data-aos-delay="500"
            data-aos-duration="700"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
          >
            Cada industria tiene necesidades distintas. En Aicraft adaptamos nuestro enfoque
            para desarrollar soluciones personalizadas que no solo mejoran la eficiencia, sino
            que llevan a tu empresa a redefinir el éxito en la era digital. Descubre algunos
            ejemplos de casos de uso de Inteligencia Artificial, Ciencia de Datos, Inteligencia de Negocios,
            Datos y Analítica para tu industria.
          </span>
        </div>
        <div
          className="industriesContainer"
          data-aos="fade-up"
          data-aos-offset="100"
          data-aos-delay="500"
          data-aos-duration="600"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="true"
        >
          {IndustriesData.map((item) => (
            <IndustryCard
              key={item.id}
              id={item.id}
              title={item.title}
              icon={item.icon}
              description={item.description}
              onCardClick={handleModalInformation}
            />
          ))}
        </div>
        <div className="sectionColumnContainer textCenter">
          <div className="dividerGradientBar" />
          <span className="sectionTitle">
            <span
              data-aos="fade-zoom-in"
              data-aos-offset="100"
              data-aos-delay="200"
              data-aos-duration="700"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="true"
            >
              Más Aplicaciones,
            </span>
            <span
              className="gradientText"
              style={{ fontWeight: 'bold' }}
              data-aos="fade-zoom-in"
              data-aos-offset="100"
              data-aos-delay="1000"
              data-aos-duration="700"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="true"
            >
              Más Industrias
            </span>
          </span>
          <div
            className="sectionColumnContainer textCenter sectionDescription"
            style={{ gap: '10px' }}
            data-aos="fade-up"
            data-aos-offset="100"
            data-aos-delay="1400"
            data-aos-duration="600"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
          >
            <span>
              ¿No encontraste tu industria o aplicación?
            </span>
            <span>
              Contamos con más opciones para apoyarte a aprovechar tus datos para crear soluciones innovadoras.
            </span>
            <span>
              Innovemos juntos.
            </span>
          </div>
          <div
            className="featureButtons"
            style={{ marginTop: '2rem' }}
            data-aos="fade-zoom-in"
            data-aos-offset="100"
            data-aos-delay="1700"
            data-aos-duration="600"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
          >
            <div className="featuresButtonContainerPr">
              <Link to="/contact">
                <button type="button" className="featureBtns">Contáctanos</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      { modalInfo.length > 0
        && (
        <>
          <Modal
            show={showModal}
            onClose={() => setShowModal(false)}
          >
            <Carousel
              additionalTransfrom={0}
              arrows={false}
              autoPlay
              autoPlaySpeed={1}
              centerMode
              className=""
              containerClass="container-with-dots"
              customTransition="all 3s linear"
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1224,
                  },
                  items: 2,
                  partialVisibilityGutter: 0,
                },
                tablet: {
                  breakpoint: {
                    max: 1224,
                    min: 600,
                  },
                  items: 1,
                  partialVisibilityGutter: 0,
                },
                mobile: {
                  breakpoint: {
                    max: 600,
                    min: 0,
                  },
                  items: 1,
                  partialVisibilityGutter: 0,
                },
              }}
              rewind={false}
              rewindWithAnimation={false}
              rtl={false}
              showDots={false}
              sliderClass=""
              slidesToSlide={1}
              swipeable
              transitionDuration={3000}
            >
              {modalInfo.map((item) => (
                <IndUseCaseCard
                  key={item.key}
                  img={item.img}
                  industry={item.industry}
                  title={item.title}
                />
              ))}
            </Carousel>
            <div className="textCenter" style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ margin: 'auto', marginTop: '1rem', textAlign: 'center', fontSize: '1.3rem', fontWeight: 600 }}>
                ¿Tienes más ideas?
              </span>
              <span style={{ margin: 'auto', marginTop: '8px', textAlign: 'center', fontSize: '1rem' }}>
                Contáctanos para conocer más casos de uso para tu industria
              </span>
            </div>
            <div className="featureButtons" style={{ margin: '0.6rem auto', marginTop: '1rem' }}>
              <div className="featuresButtonContainerPr">
                <Link to="/contact" state={{ industry: selectedIndustry }}>
                  <button type="button" className="featureBtns">Comencemos</button>
                </Link>
              </div>
            </div>
          </Modal>
        </>
        )}
    </div>
  );
}

export default Industries;
