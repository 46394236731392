import Agriculture from '../../../assets/industries/icons/Agriculture_Icon.png';
import CarManufacturing from '../../../assets/industries/icons/CarManufacturing_Icon.png';
import Cybersecurity from '../../../assets/industries/icons/Cybersecurity_Icon.png';
import Education from '../../../assets/industries/icons/Education_Icon.png';
import Energy from '../../../assets/industries/icons/Energy_Icon.png';
import Finance from '../../../assets/industries/icons/Finance_Icon.png';
import Food from '../../../assets/industries/icons/Food_Icon.png';
import Health from '../../../assets/industries/icons/Health_Icon.png';
import HumanResources from '../../../assets/industries/icons/HumanResources_Icon.png';
import Insurance from '../../../assets/industries/icons/Insurance_Icon.png';
import LawGovernment from '../../../assets/industries/icons/LawGovernment_Icon.png';
import Marketing from '../../../assets/industries/icons/Marketing_Icon.png';
import Retail from '../../../assets/industries/icons/Retail_Icon.png';
import SupplyChain from '../../../assets/industries/icons/SupplyChain_Icon.png';
import Telecomm from '../../../assets/industries/icons/Telecomm_Icon.png';
import Travel from '../../../assets/industries/icons/Travel_Icon.png';

import FinanceFraud from '../../../assets/industries/Finance/fraud.webp';
import FinanceCreditScoring from '../../../assets/industries/Finance/creditscore.webp';
import FinancePropensity from '../../../assets/industries/Finance/propensity.webp';
import FinanceCustomerProfile from '../../../assets/industries/Finance/customer_profiles.webp';
import FinanceMarketSentiment from '../../../assets/industries/Finance/marketsentiment.webp';
import FinanceProductRecom from '../../../assets/industries/Finance/productRecom.webp';
import FinanceCustomerValue from '../../../assets/industries/Finance/curstomer_value.webp';
import FinanceDataFramework from '../../../assets/industries/Finance/dataframework.webp';
import FinanceChatbots from '../../../assets/industries/Finance/chatbots.webp';
import FinanceNewsAnalysis from '../../../assets/industries/Finance/newsAnalysis.webp';
import FinanceDynamic from '../../../assets/industries/Finance/dynamic.webp';
import FinanceAlternative from '../../../assets/industries/Finance/alternative.webp';
import FinanceRiskFactors from '../../../assets/industries/Finance/risk_factors.webp';
import FinancePolitics from '../../../assets/industries/Finance/compliance_regu.webp';

import InsuranceSubscription from '../../../assets/industries/Insurance/suscription.webp';
import InsurancePrediction from '../../../assets/industries/Insurance/rate_opt.webp';
import InsurancePolicy from '../../../assets/industries/Insurance/policy_per.webp';
import InsuranceCancel from '../../../assets/industries/Insurance/cancel_pred.webp';
import InsuranceDamage from '../../../assets/industries/Insurance/damage.webp';
import InsuranceClaimOpt from '../../../assets/industries/Insurance/claim_opt.webp';
import InsuranceModeling from '../../../assets/industries/Insurance/premium.webp';
import InsuranceFraud from '../../../assets/industries/Insurance/frauds.webp';
import InsuranceFees from '../../../assets/industries/Insurance/claim_pred.webp';
import InsuranceValue from '../../../assets/industries/Insurance/customer_value.webp';

import AgricultureIrrigation from '../../../assets/industries/Agriculture/optimizacion_riego.webp';
import AgricultureCrop from '../../../assets/industries/Agriculture/opt_cultivo.webp';
import AgricultureSoil from '../../../assets/industries/Agriculture/analisis_suelo.webp';
import AgricultureFeed from '../../../assets/industries/Agriculture/opt_alimentacion.webp';
import AgriculturePlague from '../../../assets/industries/Agriculture/plagas_enfermedades.webp';
import AgricultureClimate from '../../../assets/industries/Agriculture/prediccion_clima.webp';
import AgricultureFertilization from '../../../assets/industries/Agriculture/fertilizacion.webp';
import AgricultureProduction from '../../../assets/industries/Agriculture/analisis_costos.webp';
import AgricultureData from '../../../assets/industries/Agriculture/almacenes_datos.webp';

import CarsMaintenance from '../../../assets/industries/Cars/predictive_maintenance.webp';
import CarsQA from '../../../assets/industries/Cars/quality.webp';
import CarsSensors from '../../../assets/industries/Cars/analisis_sensores.webp';
import CarsSales from '../../../assets/industries/Cars/ventas.webp';
import CarsProdCost from '../../../assets/industries/Cars/production_cost.webp';
import CarsRevenue from '../../../assets/industries/Cars/revenue.webp';
import CarsProdDash from '../../../assets/industries/Cars/production_dashboards.webp';
import CarsProfitability from '../../../assets/industries/Cars/otro2.webp';
import CarsPolicy from '../../../assets/industries/Cars/policy.webp';

import CyberPhishing from '../../../assets/industries/CyberSecurity/phishing.webp';
import CyberVulnerable from '../../../assets/industries/CyberSecurity/vulnerabilidad.webp';
import CyberIncident from '../../../assets/industries/CyberSecurity/answer_aut.webp';
import CyberFacial from '../../../assets/industries/CyberSecurity/face.webp';
import CyberFiltering from '../../../assets/industries/CyberSecurity/content_filter.webp';
import CyberMonitoring from '../../../assets/industries/CyberSecurity/monitoreo.webp';
import CyberAnomalies from '../../../assets/industries/CyberSecurity/anomalias.webp';
import CyberAssessment from '../../../assets/industries/CyberSecurity/evaluacion_controles.webp';
import CyberPolitics from '../../../assets/industries/CyberSecurity/otro.webp';

import EducationTests from '../../../assets/industries/Education/auto_exams.webp';
import EducationProfiling from '../../../assets/industries/Education/student_profiling.webp';
import EducationLearning from '../../../assets/industries/Education/learning_paths.webp';
import EducationCheating from '../../../assets/industries/Education/cheating_detect.webp';
import EducationSentiement from '../../../assets/industries/Education/sentiment.webp';
import EducationCurricular from '../../../assets/industries/Education/curricula.webp';
import EducationViolence from '../../../assets/industries/Education/violence.webp';
import EducationClasses from '../../../assets/industries/Education/pase_lista.webp';
import EducationDesertion from '../../../assets/industries/Education/desercion_exito.webp';
import EducationPerformance from '../../../assets/industries/Education/dash_performance.webp';
import EducationMarket from '../../../assets/industries/Education/graduation_market.webp';
import EducationDigitalization from '../../../assets/industries/Education/digitalization.webp';

import EnergyClean from '../../../assets/industries/Energy/analysisfor_green_energy.webp';
import EnergyClever from '../../../assets/industries/Energy/opt_redes_energeticas.webp';
import EnergyDemand from '../../../assets/industries/Energy/demand_analysis.webp';
import EnergyFares from '../../../assets/industries/Energy/opt_tarias.webp';
import EnergyConsumption from '../../../assets/industries/Energy/otro2.webp';
import EnergyConsumptionPatterns from '../../../assets/industries/Energy/patrones_consumo.webp';
import EnergyData from '../../../assets/industries/Energy/opt_almacenes_datos.webp';

import HealthDiagnosis from '../../../assets/industries/Health/diagnostico_asistido.webp';
import HealthDetection from '../../../assets/industries/Health/deteccion.webp';
import HealthChatbots from '../../../assets/industries/Health/chatbot.webp';
import HealthReviews from '../../../assets/industries/Health/opinions_analysis.webp';
import HealthResources from '../../../assets/industries/Health/resources_planification.webp';
import HealthHistory from '../../../assets/industries/Health/revision_historial.webp';
import HealthPrediction from '../../../assets/industries/Health/prediccion_reingreso.webp';
import HealthSupply from '../../../assets/industries/Health/optimi_suministros.webp';
import HealthAnomalies from '../../../assets/industries/Health/anomalias.webp';
import HealthTendencies from '../../../assets/industries/Health/public_health.webp';
import HealthDigitalization from '../../../assets/industries/Health/digitalizacion.webp';

import HRRecruiting from '../../../assets/industries/HR/reclutamiento.webp';
import HRFollowup from '../../../assets/industries/HR/seguimiento.webp';
import HRAssistant from '../../../assets/industries/HR/asistentes_entrevista.webp';
import HRChatbots from '../../../assets/industries/HR/chatbots.webp';
import HRCapacitacion from '../../../assets/industries/HR/asistente_capacitacion.webp';
import HRLearning from '../../../assets/industries/HR/rutas_aprendizaje.webp';
import HRAmbience from '../../../assets/industries/HR/other2.webp';
import HRTalent from '../../../assets/industries/HR/gestion_talento.webp';
import HRPerformance from '../../../assets/industries/HR/analisis_desempano.webp';
import HRRetention from '../../../assets/industries/HR/retencion.webp';
import HRKPI from '../../../assets/industries/HR/kpi.webp';

import LawDocuments from '../../../assets/industries/Law/revision_documentos.webp';
import LawInteligentAssistant from '../../../assets/industries/Law/chats_asistencia_ciudadana.webp';
import LawLegalAssistant from '../../../assets/industries/Law/asistentes_legal.webp';
import LawRuling from '../../../assets/industries/Law/sentecias_analisis.webp';
import LawPublic from '../../../assets/industries/Law/analisis_opinion.webp';
import LawVotes from '../../../assets/industries/Law/analisis_voto.webp';
import LawImpact from '../../../assets/industries/Law/analisis_impacto_politicas.webp';
import LawSecurity from '../../../assets/industries/Law/seguridad_inteligente.webp';
import LawPlates from '../../../assets/industries/Law/speed.webp';
import LawTramites from '../../../assets/industries/Law/tramites.webp';
import LawPolicy from '../../../assets/industries/Law/ai_policy.webp';
import LawMarket from '../../../assets/industries/Law/mercados_digitales.webp';
import LawServices from '../../../assets/industries/Law/servicios_digitales.webp';
import LawDB from '../../../assets/industries/Law/otro2.webp';

import MarketingChatbots from '../../../assets/industries/Marketing/chatbot.webp';
import MarketingRecomendation from '../../../assets/industries/Marketing/product_recom.webp';
import MarketingMarketAnalisys from '../../../assets/industries/Marketing/market_analysis.webp';
import MarketingSurvey from '../../../assets/industries/Marketing/encuestador_aut.webp';
import MarketingSurverAnalysis from '../../../assets/industries/Marketing/analisis_encuestas.webp';
import MarketingContent from '../../../assets/industries/Marketing/cracion_contenido.webp';
import MarketingForsake from '../../../assets/industries/Marketing/prediccion_abandono.webp';
import MarketingChannelAnalysis from '../../../assets/industries/Marketing/analysis_canales_market.webp';
import MarketingCampaign from '../../../assets/industries/Marketing/visual_campanas.webp';
import MarketingImpact from '../../../assets/industries/Marketing/promociones.webp';
import MarketingSalesAssistant from '../../../assets/industries/Marketing/asistentes_ventas.webp';

import RetailRecommendation from '../../../assets/industries/Retail/product_recom.webp';
import RetailDemand from '../../../assets/industries/Retail/demand_prediction.webp';
import RetailPrices from '../../../assets/industries/Retail/price_opt.webp';
import RetailFraud from '../../../assets/industries/Retail/fraud.webp';
import RetailInventory from '../../../assets/industries/Retail/gestion_inventario.webp';
import RetailSellerScore from '../../../assets/industries/Retail/scores_sellers.webp';
import RetailReview from '../../../assets/industries/Retail/analisis_resenas.webp';
import RetailSegmentation from '../../../assets/industries/Retail/segmentation.webp';
import RetailCart from '../../../assets/industries/Retail/abandono_carrito.webp';
import RetailSalesPerformance from '../../../assets/industries/Retail/dashboards.webp';
import RetailSellerAssistant from '../../../assets/industries/Retail/paneles_vendedores.webp';

import SupplyRoutes from '../../../assets/industries/SupplyChain/opti_rutas.webp';
import SupplyDemand from '../../../assets/industries/SupplyChain/pred_demanda.webp';
import SupplyInventory from '../../../assets/industries/SupplyChain/inventarios.webp';
import SupplyAnomalies from '../../../assets/industries/SupplyChain/anomaly.webp';
import SupplyProcesses from '../../../assets/industries/SupplyChain/automatizacion.webp';
import SupplyCosts from '../../../assets/industries/SupplyChain/costos1.webp';
import SupplyData from '../../../assets/industries/SupplyChain/monitoring.webp';

import TelecomNetwork from '../../../assets/industries/Telecomm/optimizacion_redes.webp';
import TelecomInfrastructure from '../../../assets/industries/Telecomm/mantenimiento.webp';
import TelecomFraud from '../../../assets/industries/Telecomm/other2.webp';
import TelecomAssistant from '../../../assets/industries/Telecomm/otro.webp';
import TelecomCustom from '../../../assets/industries/Telecomm/personalizada_oferta.webp';
import TelecomRetention from '../../../assets/industries/Telecomm/retencion.webp';
import TelecomUsage from '../../../assets/industries/Telecomm/pred_consumo.webp';
import TelecomFares from '../../../assets/industries/Telecomm/opti_tarifas.webp';
import TelecomDashboard from '../../../assets/industries/Telecomm/dashboards.webp';

import TravelRecom from '../../../assets/industries/TH/travel_recommendation.webp';
import TravelOpinion from '../../../assets/industries/TH/opiniones.webp';
import TravelChatbot from '../../../assets/industries/TH/chatbots.webp';
import TravelFraud from '../../../assets/industries/TH/fraud.webp';
import TravelActivities from '../../../assets/industries/TH/activities.webp';
import TravelDemand from '../../../assets/industries/TH/demand.webp';
import TravelSatisfaction from '../../../assets/industries/TH/satisfaccion.webp';
import TravelRoutes from '../../../assets/industries/TH/opt_rutas.webp';
import TravelCancel from '../../../assets/industries/TH/cancellations.webp';
import TravelMarket from '../../../assets/industries/TH/other3.webp';

const industriesData = [
  {
    id: 1,
    title: 'Finanzas',
    icon: Finance,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
    children: [
      {
        key: 1,
        img: FinanceFraud,
        industry: 'Inteligencia Artificial',
        title: 'Detección de Fraudes con Redes de Grafos',
        text: '',
      },
      {
        key: 2,
        img: FinanceCreditScoring,
        industry: 'Ciencia de Datos',
        title: 'Credit Scoring',
        text: '',
      },
      {
        key: 3,
        img: FinancePropensity,
        industry: 'Inteligencia Artificial',
        title: 'Modelos de Propensión',
        text: '',
      },
      {
        key: 4,
        img: FinanceCustomerProfile,
        industry: 'Inteligencia de Negocios',
        title: 'Perfilamiento de Clientes',
        text: '',
      },
      {
        key: 5,
        img: FinanceMarketSentiment,
        industry: 'Ciencia de Datos',
        title: 'Análisis de Sentimiento de Mercado',
        text: '',
      },
      {
        key: 6,
        img: FinanceProductRecom,
        industry: 'Inteligencia de Negocios',
        title: 'Recomendación de Productos',
        text: '',
      },
      {
        key: 7,
        img: FinanceCustomerValue,
        industry: 'Inteligencia de Negocios',
        title: 'Customer Lifetime Value Analysis',
        text: '',
      },
      {
        key: 8,
        img: FinanceDataFramework,
        industry: 'Políticas',
        title: 'Creación de Marcos de Gobierno de Datos',
        text: '',
      },
      {
        key: 9,
        img: FinanceChatbots,
        industry: 'Inteligencia Artificial',
        title: 'Chatbots para Consejos Financieros Personalizados ',
        text: '',
      },
      {
        key: 10,
        img: FinanceNewsAnalysis,
        industry: 'Inteligencia Artificial',
        title: 'Análisis de Noticias Financieras',
        text: '',
      },
      {
        key: 11,
        img: FinanceDynamic,
        industry: 'Ciencia de Datos',
        title: 'Pricing Dinámico',
        text: '',
      },
      {
        key: 12,
        img: FinanceAlternative,
        industry: 'Ciencia de Datos',
        title: 'Inversiones Alternativas',
        text: '',
      },
      {
        key: 13,
        img: FinanceRiskFactors,
        industry: 'Ciencia de Datos',
        title: 'Risk Factor Analysis',
        text: '',
      },
      {
        key: 14,
        img: FinancePolitics,
        industry: 'Políticas',
        title: 'Análisis de Compliance Regulatorio',
        text: '',
      },
    ],
  },
  {
    id: 2,
    title: 'Seguros',
    icon: Insurance,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
    children: [
      {
        key: 1,
        img: InsuranceSubscription,
        industry: 'Inteligencia Artificial',
        title: 'Modelos de Suscripción Automatizados',
        text: '',
      },
      {
        key: 2,
        img: InsurancePrediction,
        industry: 'Ciencia de Datos',
        title: 'Predicción de Siniestros',
        text: '',
      },
      {
        key: 3,
        img: InsurancePolicy,
        industry: 'Inteligencia Artificial',
        title: 'Personalización de Pólizas',
        text: '',
      },
      {
        key: 4,
        img: InsuranceCancel,
        industry: 'Inteligencia Artificial',
        title: 'Predicción de Cancelaciones',
        text: '',
      },
      {
        key: 5,
        img: InsuranceDamage,
        industry: 'Inteligencia Artificial',
        title: 'Evaluación de Daños Automatizada',
        text: '',
      },
      {
        key: 6,
        img: InsuranceClaimOpt,
        industry: 'Inteligencia Artificial',
        title: 'Optimización de Reclamos',
        text: '',
      },
      {
        key: 7,
        img: InsuranceModeling,
        industry: 'Inteligencia de Negocios',
        title: 'Modelado de Vida Útil del Cliente',
        text: '',
      },
      {
        key: 8,
        img: InsuranceFraud,
        industry: 'Inteligencia Artificial',
        title: 'Detección de Fraudes en Reclamaciones',
        text: '',
      },
      {
        key: 9,
        img: InsuranceFees,
        industry: 'Ciencia de Datos',
        title: 'Optimización de Tarifas',
        text: '',
      },
      {
        key: 10,
        img: InsuranceValue,
        industry: 'Ciencia de Datos',
        title: 'Modelos de Primas',
        text: '',
      },
    ],
  },
  {
    id: 3,
    title: 'Agricultura y Ganadería',
    icon: Agriculture,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
    children: [
      {
        key: 1,
        img: AgricultureIrrigation,
        industry: 'Inteligencia Artificial',
        title: 'Optimización del Riego',
        text: '',
      },
      {
        key: 2,
        img: AgricultureCrop,
        industry: 'Inteligencia Artificial',
        title: 'Optimización de Cultivo',
        text: '',
      },
      {
        key: 3,
        img: AgricultureSoil,
        industry: 'Ciencia de Datos',
        title: 'Análisis de Suelo Inteligente',
        text: '',
      },
      {
        key: 4,
        img: AgricultureFeed,
        industry: 'Inteligencia Artificial',
        title: 'Optimización de Alimentación',
        text: '',
      },
      {
        key: 5,
        img: AgriculturePlague,
        industry: 'Inteligencia Artificial',
        title: 'Detección de Plagas y Enfermedades',
        text: '',
      },
      {
        key: 6,
        img: AgricultureClimate,
        industry: 'Inteligencia Artificial',
        title: 'Predicción Climática',
        text: '',
      },
      {
        key: 7,
        img: AgricultureFertilization,
        industry: 'Inteligencia Artificial',
        title: 'Optimización de Fertilización',
        text: '',
      },
      {
        key: 8,
        img: AgricultureProduction,
        industry: 'Ciencia de Datos',
        title: 'Predicción de Costos de Producción',
        text: '',
      },
      {
        key: 9,
        img: AgricultureData,
        industry: 'Servicios de Data',
        title: 'Optimización de Almacenes de Datos para Análisis Agrícola',
        text: '',
      },
    ],
  },
  {
    id: 4,
    title: 'Vehículos y Manufactura',
    icon: CarManufacturing,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
    children: [
      {
        key: 1,
        img: CarsMaintenance,
        industry: 'Ciencia de Datos',
        title: 'Mantenimiento Predictivo',
        text: '',
      },
      {
        key: 2,
        img: CarsQA,
        industry: 'Inteligencia Artificial',
        title: 'Control de Calidad Asistido por Visión Artificial',
        text: '',
      },
      {
        key: 3,
        img: CarsSensors,
        industry: 'Ciencia de Datos',
        title: 'Análisis de Datos de Sensores',
        text: '',
      },
      {
        key: 4,
        img: CarsSales,
        industry: 'Ciencia de Datos',
        title: 'Análisis Predictivo de Ventas',
        text: '',
      },
      {
        key: 5,
        img: CarsProdCost,
        industry: 'Inteligencia Artificial',
        title: 'Predicción de Costos de Producción',
        text: '',
      },
      {
        key: 6,
        img: CarsRevenue,
        industry: 'Inteligencia Artificial',
        title: 'Predicción de Revenue',
        text: '',
      },
      {
        key: 7,
        img: CarsProdDash,
        industry: 'Inteligencia de Negocios',
        title: 'Dashboards de Desempeño de Producción',
        text: '',
      },
      {
        key: 8,
        img: CarsProfitability,
        industry: 'Ciencia de Datos',
        title: 'Análisis de Rentabilidad de Productos',
        text: '',
      },
      {
        key: 9,
        img: CarsPolicy,
        industry: 'Políticas',
        title: 'Análisis de Impacto de Cambios Normativos',
        text: '',
      },
    ],
  },
  {
    id: 5,
    title: 'Seguridad',
    icon: Cybersecurity,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
    children: [
      {
        key: 1,
        img: CyberPhishing,
        industry: 'Inteligencia Artificial',
        title: 'Detección de Spam y Phishing',
        text: '',
      },
      {
        key: 2,
        img: CyberVulnerable,
        industry: 'Ciencia de Datos',
        title: 'Análisis de Vulnerabilidad',
        text: '',
      },
      {
        key: 3,
        img: CyberIncident,
        industry: 'Inteligencia Artificial',
        title: 'Automatización de Respuestas a Incidentes',
        text: '',
      },
      {
        key: 4,
        img: CyberFacial,
        industry: 'Inteligencia Artificial',
        title: 'Reconocimiento Facial',
        text: '',
      },
      {
        key: 5,
        img: CyberFiltering,
        industry: 'Inteligencia Artificial',
        title: 'Filtrado de Contenido Malicioso',
        text: '',
      },
      {
        key: 6,
        img: CyberMonitoring,
        industry: 'Inteligencia de Negocios',
        title: 'Dashboards de Monitoreo de Seguridad',
        text: '',
      },
      {
        key: 7,
        img: CyberAnomalies,
        industry: 'Ciencia de Datos',
        title: 'Detección de Anomalías en Tráfico de Red',
        text: '',
      },
      {
        key: 8,
        img: CyberAssessment,
        industry: 'Políticas',
        title: 'Evaluaciones de Controles Internos',
        text: '',
      },
      {
        key: 9,
        img: CyberPolitics,
        industry: 'Políticas',
        title: 'Revisión de Políticas Internas',
        text: '',
      },
    ],
  },
  {
    id: 6,
    title: 'Educación',
    icon: Education,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
    children: [
      {
        key: 1,
        img: EducationTests,
        industry: 'Ciencia de Datos',
        title: 'Evaluaciones Automatizadas',
        text: '',
      },
      {
        key: 2,
        img: EducationProfiling,
        industry: 'Ciencia de Datos',
        title: 'Perfilamiento de Estudiantes',
        text: '',
      },
      {
        key: 3,
        img: EducationLearning,
        industry: 'Ciencia de Datos',
        title: 'Recomendaciones de Rutas de Aprendizaje',
        text: '',
      },
      {
        key: 4,
        img: EducationCheating,
        industry: 'Inteligencia Artificial',
        title: 'Detección de Trampas en Evaluaciones',
        text: '',
      },
      {
        key: 5,
        img: EducationSentiement,
        industry: 'Inteligencia Artificial',
        title: 'Análisis de Sentimiento y Patrones de Aprendizaje',
        text: '',
      },
      {
        key: 6,
        img: EducationCurricular,
        industry: 'Ciencia de Datos',
        title: 'Optimización de la Planificación Curricular',
        text: '',
      },
      {
        key: 7,
        img: EducationViolence,
        industry: 'Inteligencia Artificial',
        title: 'Detección de Violencia en Instalaciones Educativas',
        text: '',
      },
      {
        key: 8,
        img: EducationClasses,
        industry: 'Inteligencia Artificial',
        title: 'Pases de lista e Inicio de Clases Automatizado',
        text: '',
      },
      {
        key: 9,
        img: EducationDesertion,
        industry: 'Ciencia de Datos',
        title: 'Predicción de Deserción Escolar y Éxito Estudiantil',
        text: '',
      },
      {
        key: 10,
        img: EducationPerformance,
        industry: 'Inteligencia de Negocios',
        title: 'Dashboards de Desempeño Académico',
        text: '',
      },
      {
        key: 11,
        img: EducationMarket,
        industry: 'Inteligencia de Negocios',
        title: 'Análisis de Mercado de Empleo para Graduados',
        text: '',
      },
      {
        key: 12,
        img: EducationDigitalization,
        industry: 'Inteligencia Artificial',
        title: 'Digitalización de Documentos Automática',
        text: '',
      },
    ],
  },
  {
    id: 7,
    title: 'Energía y servicios',
    icon: Energy,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
    children: [
      {
        key: 1,
        img: EnergyClean,
        industry: 'Ciencia de Datos',
        title: 'Análisis para Adopción de Energías Limpias',
        text: '',
      },
      {
        key: 2,
        img: EnergyClever,
        industry: 'Inteligencia Artificial',
        title: 'Optimización de Redes Eléctricas Inteligente',
        text: '',
      },
      {
        key: 3,
        img: EnergyDemand,
        industry: 'Inteligencia Artificial',
        title: 'Análisis Predictivo de Demanda Energética',
        text: '',
      },
      {
        key: 4,
        img: EnergyFares,
        industry: 'Ciencia de Datos',
        title: 'Optimización de Tarifas Energéticas',
        text: '',
      },
      {
        key: 5,
        img: EnergyConsumption,
        industry: 'Ciencia de Datos',
        title: 'Modelado de Consumo Energético',
        text: '',
      },
      {
        key: 6,
        img: EnergyConsumptionPatterns,
        industry: 'Inteligencia Artificial',
        title: 'Detección de Patrón de Consumo',
        text: '',
      },
      {
        key: 7,
        img: EnergyData,
        industry: 'Servicios de Data',
        title: 'Optimización de Almacenes de Datos para Análisis Energético',
        text: '',
      },
    ],
  },
  {
    id: 8,
    title: 'Alimentos',
    icon: Food,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
    children: [
      {
        key: 1,
        img: SupplyCosts,
        industry: 'Inteligencia de Negocios',
        title: 'Optimización de Precios',
        text: '',
      },
      {
        key: 2,
        img: SupplyDemand,
        industry: 'Ciencia de Datos',
        title: 'Predicción de Ventas e Inventarios',
        text: '',
      },
      {
        key: 3,
        img: MarketingCampaign,
        industry: 'Ciencia de Datos',
        title: 'Análisis de Tendencias de Consumo',
        text: '',
      },
      {
        key: 4,
        img: RetailSellerAssistant,
        industry: 'Inteligencia de Negocios',
        title: 'Análisis de Compras Conjuntas de Productos',
        text: '',
      },
      {
        key: 5,
        img: RetailDemand,
        industry: 'Ciencia de Datos',
        title: 'Predicción de Demanda para Nuevos Productos',
        text: '',
      },
      {
        key: 6,
        img: TelecomFraud,
        industry: 'Inteligencia Artificial',
        title: 'Segmentación de Demanda Regional',
        text: '',
      },
      {
        key: 7,
        img: RetailRecommendation,
        industry: 'Inteligencia Artificial',
        title: 'Nutrición Personalizada',
        text: '',
      },
    ],
  },
  {
    id: 9,
    title: 'Salud',
    icon: Health,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
    children: [
      {
        key: 1,
        img: HealthDiagnosis,
        industry: 'Inteligencia Artificial',
        title: 'Diagnóstico Asistido por IA',
        text: '',
      },
      {
        key: 2,
        img: HealthDetection,
        industry: 'Ciencia de Datos',
        title: 'Detección de Enfermedades',
        text: '',
      },
      {
        key: 3,
        img: HealthChatbots,
        industry: 'Inteligencia Artificial',
        title: 'Chatbots de Atención Inmediata a Pacientes',
        text: '',
      },
      {
        key: 4,
        img: HealthReviews,
        industry: 'Ciencia de Datos',
        title: 'Análisis de Opiniones de Clientes',
        text: '',
      },
      {
        key: 5,
        img: HealthResources,
        industry: 'Inteligencia de Negocios',
        title: 'Planificación de Recursos en Hospitales',
        text: '',
      },
      {
        key: 6,
        img: HealthHistory,
        industry: 'Ciencia de Datos',
        title: 'Revisión y Evaluación de Historial Médico',
        text: '',
      },
      {
        key: 7,
        img: HealthPrediction,
        industry: 'Inteligencia Artificial',
        title: 'Predicción de Reingreso Hospitalario',
        text: '',
      },
      {
        key: 8,
        img: HealthSupply,
        industry: 'Inteligencia de Negocios',
        title: 'Optimización de Cadena de Suministro de Medicamentos',
        text: '',
      },
      {
        key: 9,
        img: HealthAnomalies,
        industry: 'Inteligencia Artificial',
        title: 'Detección de Anomalías en Datos Clínicos y Fraudes',
        text: '',
      },
      {
        key: 10,
        img: HealthTendencies,
        industry: 'Ciencia de Datos',
        title: 'Análisis de Tendencias de Salud Pública',
        text: '',
      },
      {
        key: 11,
        img: HealthDigitalization,
        industry: 'Servicios de Data',
        title: 'Digitalización de Expedientes',
        text: '',
      },
    ],
  },
  {
    id: 10,
    title: 'Recursos Humanos',
    icon: HumanResources,
    description:
      'Lorem ipsum dolorsit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
    children: [
      {
        key: 1,
        img: HRRecruiting,
        industry: 'Inteligencia Artificial',
        title: 'Sistemas de Reclutamiento Automatizado',
        text: '',
      },
      {
        key: 2,
        img: HRFollowup,
        industry: 'Inteligencia Artificial',
        title: 'Seguimiento de Contratación Inteligente',
        text: '',
      },
      {
        key: 3,
        img: HRAssistant,
        industry: 'Inteligencia Artificial',
        title: 'Asistentes para Entrevistas',
        text: '',
      },
      {
        key: 4,
        img: HRChatbots,
        industry: 'Inteligencia Artificial',
        title: 'Chatbots para Preguntas Frecuentes',
        text: '',
      },
      {
        key: 5,
        img: HRCapacitacion,
        industry: 'Inteligencia Artificial',
        title: 'Asistentes para Capacitaciones Introductorias a Nuevos Empleados',
        text: '',
      },
      {
        key: 6,
        img: HRLearning,
        industry: 'Ciencia de Datos',
        title: 'Personalización de Rutas de Aprendizaje y Capacitación',
        text: '',
      },
      {
        key: 7,
        img: HRAmbience,
        industry: 'Ciencia de Datos',
        title: 'Detección de Sesgos y Ambiente Laboral',
        text: '',
      },
      {
        key: 8,
        img: HRTalent,
        industry: 'Inteligencia de Negocios',
        title: 'Optimización de Gestión de Talento',
        text: '',
      },
      {
        key: 9,
        img: HRPerformance,
        industry: 'Inteligencia de Negocios',
        title: 'Análisis de Desempeño y Bonificaciones',
        text: '',
      },
      {
        key: 10,
        img: HRRetention,
        industry: 'Ciencia de Datos',
        title: 'Optimización de Estrategias de Retención',
        text: '',
      },
      {
        key: 11,
        img: HRKPI,
        industry: 'Inteligencia de Negocios',
        title: 'Monitoreo de KPIs de Desempeño y Metas',
        text: '',
      },
    ],
  },
  {
    id: 11,
    title: 'Leyes y Gobierno',
    icon: LawGovernment,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
    children: [
      {
        key: 1,
        img: LawDocuments,
        industry: 'Inteligencia Artificial',
        title: 'Revisión Automatizada de Documentos Legales',
        text: '',
      },
      {
        key: 2,
        img: LawInteligentAssistant,
        industry: 'Inteligencia Artificial',
        title: 'Asistentes Inteligentes de Asistencia Ciudadana',
        text: '',
      },
      {
        key: 3,
        img: LawLegalAssistant,
        industry: 'Inteligencia Artificial',
        title: 'Asistentes Virtuales para Consultas Legales',
        text: '',
      },
      {
        key: 4,
        img: LawRuling,
        industry: 'Inteligencia Artificial',
        title: 'Análisis de Sentencias Judiciales',
        text: '',
      },
      {
        key: 5,
        img: LawPublic,
        industry: 'Inteligencia Artificial',
        title: 'Análisis de Opiniones Públicas',
        text: '',
      },
      {
        key: 6,
        img: LawVotes,
        industry: 'Ciencia de Datos',
        title: 'Análisis de Tendencias de Voto',
        text: '',
      },
      {
        key: 7,
        img: LawImpact,
        industry: 'Ciencia de Datos',
        title: 'Análisis de Impacto de Políticas Públicas',
        text: '',
      },
      {
        key: 8,
        img: LawSecurity,
        industry: 'Inteligencia Artificial',
        title: 'Monitoreo de Incidencias Delictivas y Seguridad Inteligente',
        text: '',
      },
      {
        key: 9,
        img: LawPlates,
        industry: 'Inteligencia Artificial',
        title: 'Detección de Placas y Velocidad',
        text: '',
      },
      {
        key: 10,
        img: LawTramites,
        industry: 'Servicios de Data',
        title: 'Servicios Públicos y Tramites Digitales',
        text: '',
      },
      {
        key: 11,
        img: LawPolicy,
        industry: 'Políticas',
        title: 'Asesoría para Políticas de IA',
        text: '',
      },
      {
        key: 12,
        img: LawMarket,
        industry: 'Políticas',
        title: 'Asesoría para Políticas de Mercados Digitales',
        text: '',
      },
      {
        key: 13,
        img: LawServices,
        industry: 'Políticas',
        title: 'Asesoría para Políticas para Servicios Digitales',
        text: '',
      },
      {
        key: 14,
        img: LawDB,
        industry: 'Servicios de Data',
        title: 'Creación de Bases de Datos',
        text: '',
      },
    ],
  },
  {
    id: 12,
    title: 'Marketing',
    icon: Marketing,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
    children: [
      {
        key: 1,
        img: MarketingChatbots,
        industry: 'Inteligencia Artificial',
        title: 'Chatbots para Atención al Cliente',
        text: '',
      },
      {
        key: 2,
        img: MarketingRecomendation,
        industry: 'Inteligencia Artificial',
        title: 'Recomendaciones Personalizadas de Productos',
        text: '',
      },
      {
        key: 3,
        img: MarketingMarketAnalisys,
        industry: 'Ciencia de Datos',
        title: 'Análisis de Mercado Inteligente',
        text: '',
      },
      {
        key: 4,
        img: MarketingSurvey,
        industry: 'Inteligencia Artificial',
        title: 'Encuestador Automático',
        text: '',
      },
      {
        key: 5,
        img: MarketingSurverAnalysis,
        industry: 'Ciencia de Datos',
        title: 'Análisis de Encuestas',
        text: '',
      },
      {
        key: 6,
        img: MarketingContent,
        industry: 'Inteligencia Artificial',
        title: 'Creación de Contenido Automatizada',
        text: '',
      },
      {
        key: 7,
        img: MarketingForsake,
        industry: 'Ciencia de Datos',
        title: 'Predicción de Abandono de Clientes',
        text: '',
      },
      {
        key: 8,
        img: MarketingChannelAnalysis,
        industry: 'Inteligencia de Negocios',
        title: 'Análisis de Efectividad de Canales de Marketing',
        text: '',
      },
      {
        key: 9,
        img: MarketingCampaign,
        industry: 'Inteligencia de Negocios',
        title: 'Visualización de Datos de Campañas',
        text: '',
      },
      {
        key: 10,
        img: MarketingImpact,
        industry: 'Ciencia de Datos',
        title: 'Análisis de Impacto de Promociones',
        text: '',
      },
      {
        key: 11,
        img: MarketingSalesAssistant,
        industry: 'Inteligencia Artificial',
        title: 'Asistentes Inteligentes para Ventas',
        text: '',
      },
    ],
  },
  {
    id: 13,
    title: 'Retail',
    icon: Retail,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
    children: [
      {
        key: 1,
        img: RetailRecommendation,
        industry: 'Inteligencia Artificial',
        title: 'Recomendaciones de Productos Personalizadas',
        text: '',
      },
      {
        key: 2,
        img: RetailDemand,
        industry: 'Ciencia de Datos',
        title: 'Análisis Predictivo de Demanda',
        text: '',
      },
      {
        key: 3,
        img: RetailPrices,
        industry: 'Ciencia de Datos',
        title: 'Optimización Dinámica de Precios',
        text: '',
      },
      {
        key: 4,
        img: RetailFraud,
        industry: 'Inteligencia Artificial',
        title: 'Detección de Fraudes de Vendedores y Compradores',
        text: '',
      },
      {
        key: 5,
        img: RetailInventory,
        industry: 'Inteligencia de Negocios',
        title: 'Gestión de Inventario Automatizada',
        text: '',
      },
      {
        key: 6,
        img: RetailSellerScore,
        industry: 'Ciencia de Datos',
        title: 'Scores de Riesgo de Vendedores',
        text: '',
      },
      {
        key: 7,
        img: RetailReview,
        industry: 'Inteligencia Artificial',
        title: 'Análisis de Reseñas de Clientes',
        text: '',
      },
      {
        key: 8,
        img: RetailSegmentation,
        industry: 'Inteligencia Artificial',
        title: 'Segmentación para Campañas de Marketing',
        text: '',
      },
      {
        key: 9,
        img: RetailCart,
        industry: 'Inteligencia Artificial',
        title: 'Predicción de Abandono de Carrito',
        text: '',
      },
      {
        key: 10,
        img: RetailSalesPerformance,
        industry: 'Inteligencia de Negocios',
        title: 'Dashboards de Desempeño de Ventas',
        text: '',
      },
      {
        key: 11,
        img: RetailSellerAssistant,
        industry: 'Inteligencia de Negocios',
        title: 'Paneles de Asistencia a Vendedores',
        text: '',
      },
    ],
  },
  {
    id: 14,
    title: 'Cadena de Suministros',
    icon: SupplyChain,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
    children: [
      {
        key: 1,
        img: SupplyRoutes,
        industry: 'Inteligencia Artificial',
        title: 'Optimización de Rutas de Transporte',
        text: '',
      },
      {
        key: 2,
        img: SupplyDemand,
        industry: 'Ciencia de Datos',
        title: 'Predicción de Demanda',
        text: '',
      },
      {
        key: 3,
        img: SupplyInventory,
        industry: 'Inteligencia de Negocios',
        title: 'Automatización de Inventarios',
        text: '',
      },
      {
        key: 4,
        img: SupplyAnomalies,
        industry: 'Ciencia de Datos',
        title: 'Detección de Anomalías en la Cadena de Suministro',
        text: '',
      },
      {
        key: 5,
        img: SupplyProcesses,
        industry: 'Inteligencia de Negocios',
        title: 'Automatización de Procesos de Pedido',
        text: '',
      },
      {
        key: 6,
        img: SupplyCosts,
        industry: 'Ciencia de Datos',
        title: 'Modelado de Costos de Transporte',
        text: '',
      },
      {
        key: 7,
        img: SupplyData,
        industry: 'Inteligencia de Negocios',
        title: 'Visualización de Datos de Proveedores',
        text: '',
      },
    ],
  },
  {
    id: 15,
    title: 'Telecomunicaciones',
    icon: Telecomm,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
    children: [
      {
        key: 1,
        img: TelecomNetwork,
        industry: 'Inteligencia Artificial',
        title: 'Optimización de Redes',
        text: '',
      },
      {
        key: 2,
        img: TelecomInfrastructure,
        industry: 'Ciencia de Datos',
        title: 'Mantenimiento Predictivo de Infraestructura',
        text: '',
      },
      {
        key: 3,
        img: TelecomFraud,
        industry: 'Inteligencia Artificial',
        title: 'Detección de Fraudes en Uso de Servicio',
        text: '',
      },
      {
        key: 4,
        img: TelecomAssistant,
        industry: 'Inteligencia Artificial',
        title: 'Asistentes Virtuales Inteligentes para Atención a Clientes',
        text: '',
      },
      {
        key: 5,
        img: TelecomCustom,
        industry: 'Ciencia de Datos',
        title: 'Ofertas Personalizadas para Clientes',
        text: '',
      },
      {
        key: 6,
        img: TelecomRetention,
        industry: 'Ciencia de Datos',
        title: 'Modelos de Retención de Cliente',
        text: '',
      },
      {
        key: 7,
        img: TelecomUsage,
        industry: 'Inteligencia de Negocios',
        title: 'Predicción de Consumo',
        text: '',
      },
      {
        key: 8,
        img: TelecomFares,
        industry: 'Inteligencia de Negocios',
        title: 'Optimización de Tarifas de Servicios',
        text: '',
      },
      {
        key: 9,
        img: TelecomDashboard,
        industry: 'Inteligencia de Negocios',
        title: 'Dashboards de Desempeño Operativo',
        text: '',
      },
    ],
  },
  {
    id: 16,
    title: 'Viajes y Hoteles',
    icon: Travel,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
    children: [
      {
        key: 1,
        img: TravelRecom,
        industry: 'Inteligencia Artificial',
        title: 'Recomendaciones Personalizadas de Viajes y Hoteles',
        text: '',
      },
      {
        key: 2,
        img: TravelOpinion,
        industry: 'Inteligencia Artificial',
        title: 'Análisis de Opiniones de Clientes',
        text: '',
      },
      {
        key: 3,
        img: TravelChatbot,
        industry: 'Inteligencia Artificial',
        title: 'Chatbots de Asistencia a Clientes',
        text: '',
      },
      {
        key: 4,
        img: TravelFraud,
        industry: 'Inteligencia Artificial',
        title: 'Análisis de Fraudes en Reservas',
        text: '',
      },
      {
        key: 5,
        img: TravelActivities,
        industry: 'Inteligencia Artificial',
        title: 'Sistema de Recomendación de Actividades',
        text: '',
      },
      {
        key: 6,
        img: TravelDemand,
        industry: 'Ciencia de Datos',
        title: 'Análisis Predictivo de Demanda',
        text: '',
      },
      {
        key: 7,
        img: TravelSatisfaction,
        industry: 'Ciencia de Datos',
        title: 'Análisis de Satisfacción de Clientes',
        text: '',
      },
      {
        key: 8,
        img: TravelRoutes,
        industry: 'Inteligencia Artificial',
        title: 'Optimización de Rutas de Viaje',
        text: '',
      },
      {
        key: 9,
        img: TravelCancel,
        industry: 'Ciencia de Datos',
        title: 'Predicción de Cancelaciones',
        text: '',
      },
      {
        key: 10,
        img: TravelMarket,
        industry: 'Inteligencia de Negocios',
        title: 'Investigación de Mercado para Nuevos Destinos',
        text: '',
      },
    ],
  },
];

export default industriesData;
