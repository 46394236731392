import React from 'react';
import './GuidanceCard.css';

const GuidanceCard = ({ data }) => (
  <div className="guidanceCard">
    <div className="guidanceCardImage">
      <img src={data.img} alt={data.title} />
    </div>
    <div className="guidanceCardText">
      <div className="guidanceCardMini halfTextContainer">{data.mini}</div>
      <div className="guidanceCardTitle halfTextContainer">{data.title}</div>
      <div className="guidanceCardDesc halfTextContainer">{data.desc}</div>
    </div>
  </div>
);

export default GuidanceCard;
