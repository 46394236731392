const InnoCardsData = [
  {
    number: '01.',
    title: 'Consultoría Especializada',
    description: 'Soluciones de Inteligencia Artificial, Ciencia de Datos, y Business Intelligence para transformar datos en decisiones estratégicas y resultados tangibles.',
  },
  {
    number: '02.',
    title: 'Auditoría y Peritaje Especializado',
    description: 'Evaluaciones expertas y objetivas para la adecuada implementación y uso de la IA y tecnología, con consciencia ética y regulatoria.',
  },
  {
    number: '03.',
    title: 'Política y Ética para IA y Tecnología',
    description: 'Asesoría para la revisión y creación de estándares éticos sólidos y políticas transparentes para el diseño, desarrollo y despliegue de soluciones tecnológicas.',
  },
  {
    number: '04.',
    title: 'Investigación e Innovación',
    description: 'Desarrollo de tecnología que responde a las necesidades del presente, pero anticipa y prepara el terreno para el futuro.',
  },
  {
    number: '05.',
    title: 'Educación y Entrenamiento',
    description: 'Programas de formación para que los individuos y organizaciones cuenten con las habilidades y herramientas necesarias para aprovechar todo el potencial de la IA.',
  },
];

export default InnoCardsData;
