import React from 'react';
import AOS from 'aos';
import { Link } from 'react-router-dom';
import Feature from '../../../components/feature/Feature';
import './features.css';
import 'aos/dist/aos.css';

AOS.init();

const featuresData = [
  {
    title: 'Consultoría y Desarrollo',
    dir: 'consulting',
  },
  {
    title: 'Auditoría',
    dir: '',
  },
  {
    title: 'Política y Regulación',
    dir: '',
  },
  {
    title: 'Educación y Entrenamiento',
    dir: '',
  },
];

const Features = () => (
  <div className="center" id="features">
    <h3
      className="miniTitle"
      data-aos="fade-zoom-in"
      data-aos-offset="100"
      data-aos-delay="50"
      data-aos-duration="700"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="true"
    >
      NUESTRA OFERTA
    </h3>

    <div
      data-aos="fade-up"
      data-aos-offset="100"
      data-aos-delay="50"
      data-aos-duration="700"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="true"
    >
      <h2 className="title">
        Potenc <span className="gradient__text" style={{ marginLeft: '-5px', fontSize: '1.75rem', fontWeight: 'bolder' }}>IA</span>ndo la vida y los negocios
      </h2>
      <p className="description">
        Transforma cada aspecto de tu vida y negocio con nuestras soluciones avanzadas de IA.Transformación inteligente, resultados reales.
      </p>
    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <Feature
          title={item.title}
          dir={item.dir}
          key={item.title + index}
        />
      ))}
    </div>
    <div className="featureButtons">
      {/* <div className="featuresButtonContainerAi">
        <button type="button" className="featureBtns">Por qué Aicraft</button>
      </div> */}
      <div className="featuresButtonContainerPr">
        <Link to="/contact">
          <button type="button" className="featureBtns">Posibles proyectos</button>
        </Link>
      </div>
    </div>

    <input type="text" name="username" placeholder="Your username" tabIndex="-1" autoComplete="new-password" style={{ position: 'absolute', left: '-999999999px' }} />
  </div>
);

export default Features;
