import data from '../../assets/consulting/specialties/data.webp';
import analytics from '../../assets/consulting/specialties/analytics.webp';
import intelligence from '../../assets/consulting/specialties/intelligence.jpeg';
import ai from '../../assets/consulting/specialties/ai.jpeg';
import cloud from '../../assets/consulting/specialties/cloud.jpeg';
import custom from '../../assets/consulting/specialties/custom.webp';
// import managementImg from '../../assets/industries/security.jpg';
// import implementationImg from '../../assets/industries/security.jpg';
// import optimizationImg from '../../assets/industries/security.jpg';

const consultingServicesData = [
  {
    mini: 'Data Services',
    title: 'Ofrecemos soluciones  para crear, almacenar, gestionar y optimizar bases de datos, asegurando que tu información esté siempre disponible y segura.',
    image: data,
  },
  {
    mini: 'Data Science',
    title: 'Análisis financiero, filtrados de tendencia, análisis de mercado, trading; analizamos tus datos para descubrir patrones ocultos y generar insights que impulsen el crecimiento de tu negocio.',
    image: analytics,
  },
  {
    mini: 'Analytics & Business Intelligence',
    title: 'Dashboards, KPI análisis, reportes automáticos; empodera a tu equipo con herramientas de BI que facilitan la toma de decisiones basadas en datos precisos y en tiempo real.',
    image: intelligence,
  },
  {
    mini: 'AI Solutions',
    title: 'Chatbots, sistemas de recomendación, detectores de errores, reconocimiento de objetos, análisis de texto; Desarrollamos e implementamos soluciones de IA personalizadas que automatizan procesos, optimizan operaciones y abren nuevas oportunidades ',
    image: ai,
  },
  {
    mini: 'Cloud Technology',
    title: 'Adopta la tecnología en la nube para mejorar la agilidad de tu empresa, modelos y servicios, para garantizar un acceso seguro a tus recursos desde cualquier lugar.',
    image: cloud,
  },
  {
    mini: 'Custom Services',
    title: 'Cada negocio es único. Creamos soluciones personalizadas que se alinean perfectamente con tus objetivos y desafíos específicos. Cuéntanos tus necesidades, nosotros te ofreceremos soluciones.',
    image: custom,
  },
];

export default consultingServicesData;
