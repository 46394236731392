import { React, useRef } from 'react';
import { RightCircleFilled } from '@ant-design/icons';
import './Consulting.css';

import Guidance from './guidance/Guidance';
import Services from './services/Services';
import UseCasesComponent from './useCases/UseCasesComponent';
import Advantages from './advantages/Advantages';
import { CTA } from '../../components';

function Consulting() {
  const servicesRef = useRef(null);
  return (
    <div className="consultingContainer">
      <div className="consultingHeader">
        <span className="slidefrombottom">Nuestros Servicios</span>
        <h1 className="slidefrombottom">Servicios de Consultoría y Desarrollo</h1>
        <h3 className="slidefrombottom">
          Desde desarrollar o implementar modelos analíticos, optimizar y automatizar procesos o crear dashboards, hasta implementar aplicaciones novedosas de IA, nuestro equipo de expertos les ayudara a realizar su visión.
        </h3>
        <div
          className="takemeToIt slidefrombottom"
          onClick={() => {
            servicesRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'center',
            });
          }}
        >
          <span className="ttiText">Muéstrame más</span>
          <RightCircleFilled style={{ fontSize: '24px' }} />
        </div>
      </div>
      <Guidance />
      <div ref={servicesRef}>
        <Services orientation="normal" />
      </div>
      <div className="inversetriangleContainer">
        <div className="inverseTriangle" />
      </div>
      <UseCasesComponent />
      <div className="dividerGradientBar " style={{ width: '40%', height: '3px', marginTop: '7rem', marginBottom: '7rem', marginLeft: 'auto', marginRight: 'auto' }} />
      <Advantages />
      <CTA />
    </div>
  );
}

export default Consulting;
