import React from 'react';
import './TextContainer.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

function TextContainer({ title, description, propImg }) {
  return (
    <div
      className="textContainer identidadContainer"
      data-aos="fade-up"
      data-aos-offset="200"
      data-aos-delay="50"
      data-aos-duration="700"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="true"
    >
      <div className="identidadImgContainer">
        <img className="identidadImg" src={propImg} />
      </div>
      <div className="identidadTextContainer">
        <p className="identidadTitle">
          {title}
        </p>
        <span>
          {description}
        </span>
      </div>
    </div>
  );
}

export default TextContainer;
