import React from 'react';
import { RightCircleFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import aicraftWhiteLogo from '../../../../assets/logos/aicraftLogoWhite.svg';
import './UseCaseCard.css';

export default function UseCaseCard({ img, title, industry, text }) {
  return (
    <div className="useCaseCard useCaseCardLayout" style={{ gap: text === '' ? '0' : '40px' }}>
      <div className="useCard_ImageContainer">
        {text === '' ? <img src={aicraftWhiteLogo} className="regularMR" style={{ objectFit: 'contain' }} /> : <img src={img} />}
      </div>
      <div className="useCard_TextContainer">
        <span className="useCard_TextContainer_industry">{industry}</span>
        <span className="useCard_TextContainer_title" style={{ background: text === '' ? 'var(--gradient-text-purple)' : 'var(--gradient-text-blue)', backgroundClip: 'text' }}>{title}</span>
        <span className="useCard_TextContainer_text">{text}</span>
        {text === '' && (
          <div style={{ paddingBottom: '3rem', margin: 'auto' }}>
            <Link to="/">
              <RightCircleFilled style={{ fontSize: '24px' }} />
              <span className="clickHereLink" style={{ marginLeft: '6px' }}>
                Haz clic aquí
              </span>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
