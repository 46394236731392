import React from 'react';
import AOS from 'aos';
import './InnovationCards.css';

AOS.init();

export default function InnovationCards({ number, title, description }) {
  return (
    <div
      className="innovationCard"
      data-aos="fade-right"
      data-aos-offset="100"
      data-aos-delay="50"
      data-aos-duration="700"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="true"
    >
      <h2 className="cardNumber">{number}</h2>
      <h5 className="cardTitle">{title}</h5>
      <div className="descriptionBlock">
        <div id="barraColor" />
        <span className="cardDesc">{description}</span>
      </div>
    </div>
  );
}
