import diagnosis from '../../../assets/consulting/guidance/diagnosis.jpeg';
import planning from '../../../assets/consulting/guidance/planning.jpeg';
import development from '../../../assets/consulting/guidance/development.jpeg';
import deployment from '../../../assets/consulting/guidance/deployment.jpeg';
import evaluacion from '../../../assets/consulting/guidance/evaluacion.jpeg';
import optimization from '../../../assets/consulting/guidance/optimization.jpeg';

const GuidanceCardData = {
  diagnostico: {
    img: diagnosis,
    mini: 'Diagnóstico',
    title: 'Entendemos tus objetivos y necesidades',
    desc: 'Realizamos un análisis exhaustivo de tu situación actual para identificar oportunidades y desafíos clave, asegurando una comprensión profunda antes de iniciar cualquier proyecto.',
  },
  planeacion: {
    img: planning,
    mini: 'Planeación y Gestión',
    title: 'Diseñamos tu estrategia y/o gestionamos tu proyecto',
    desc: 'Creamos un plan de acción detallado que abarca desde la estrategia inicial hasta la gestión del proyecto y el uso de negocio, alineando cada paso con los objetivos de tu organización.',
  },
  desarrollo: {
    img: development,
    mini: 'Desarrollo',
    title: 'Construimos soluciones a la medida',
    desc: 'Desarrollamos soluciones personalizadas, adaptadas a las necesidades específicas de tu empresa, utilizando las tecnologías más avanzadas.',
  },
  implementacion: {
    img: deployment,
    mini: 'Implementación',
    title: 'Hacemos realidad tu visión',
    desc: 'Nos encargamos de la implementación de las soluciones diseñadas, garantizando una integración fluida y efectiva en tu entorno empresarial.',
  },
  evaluacion: {
    img: evaluacion,
    mini: 'Evaluación',
    title: 'Medimos el impacto',
    desc: 'Evaluamos el rendimiento de las soluciones implementadas mediante métricas clave, asegurando que se cumplan los objetivos propuestos.',
  },
  optimizacion: {
    img: optimization,
    mini: 'Optimización',
    title: 'Mejoramos continuamente',
    desc: 'Identificamos oportunidades de mejora y realizamos ajustes continuos para maximizar el rendimiento y la eficiencia de las soluciones implementadas.',
  },
};

export default GuidanceCardData;
