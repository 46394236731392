import React from 'react';
import { Link } from 'react-router-dom';
import './indUseCaseCard.css';

function IndUseCaseCard({ img, industry, title, action }) {
  const selectedIndustry = `el uso "${title}"`;

  return (
    <Link to="/contact" state={{ industry: selectedIndustry }}>
      <div className="indUseCaseCard">
        <div className="indUseCaseCard__content">
          <p className="indUseCaseCard__industry">
            {industry}
          </p>
          <h3 className="indUseCaseCard__title">
            {title}
          </h3>
          { action && (
            <div>
              <button type="button" className="indUseCaseCard__action" onClick={action}>
                Learn More
              </button>
            </div>
          )}
        </div>
        <div className="indUseCaseCard__image">
          <img src={img} alt="" draggable="false" />
        </div>
      </div>
    </Link>
  );
}

export default IndUseCaseCard;
