import React from 'react';
import './HorizontalSection.css';

const HorizontalSection = ({ title, content, propImg }) => (
  <div className="horizontalSectionContainer">
    <div className="hsImg">
      <img src={propImg} alt="Industries Card" />
    </div>
    <div className="hsText">
      <span id="hsTitle">{ title }</span>
      <span id="hsContent">{ content }</span>
    </div>
  </div>
);

export default HorizontalSection;
