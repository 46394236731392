import finantialImg from '../../../assets/industries/finances.webp';
import healthImg from '../../../assets/industries/health.webp';
import securityImg from '../../../assets/industries/secutiry.webp';

const performanceCardData = [
  {
    mini: 'Finanzas y Seguros',
    title: 'Mejora tus servicios con soluciones basadas en datos.',
    image: finantialImg,
  },
  {
    mini: 'Alimentos, Salud, y Genómica',
    title: 'Optimiza tu producción, análisis y atención.',
    image: healthImg,
  },
  {
    mini: 'Seguridad',
    title: 'Mejora la precisión, eficiencia, y capacidades de tus servicios.',
    image: securityImg,
  },
  {
    mini: 'Descubre más Industrias',
    title: 'Alcanza un nuevo nivel de eficiencia y competitividad, impulsa el progreso, y posiciónate en tu sector.',
  },
];

export default performanceCardData;
