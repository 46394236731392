import MisionImg from '../../assets/aboutus/mision.jpeg';
import VisionImg from '../../assets/performance/ethics.webp';

const missiondc1 = 'Nuestra misión es proporcionar a las empresas e instituciones las herramientas y el conocimiento necesarios para integrar la IA y nuevas tecnologías de manera efectiva, promoviendo un crecimiento estratégico e innovación sostenida. Buscamos cerrar la brecha tecnológica en la región y ';
const missiondc2 = 'contribuir al desarrollo de un entorno empresarial más avanzado y competitivo. Con nuestra gama completa de servicios, profunda experiencia en la industria y un compromiso inquebrantable con la excelencia, somos su socio de confianza para el éxito en IA.';

const visiondc1 = 'Nuestra visión es ser un parteaguas en los servicios especializados en IA y tecnología en la región, así como generar investigación de vanguardia fomentando la colaboración que impulsa la innovación, el crecimiento económico y el progreso social.';
const visiondc2 = 'A través de nuestra dedicación a la excelencia y la innovación, estamos comprometidos a ser líderes en el desarrollo, investigación, y aplicación de estas tecnologías, impulsando un futuro donde la ciencia y la industria trabajen de la mano para el progreso y la prosperidad de nuestra sociedad.';

const AboutUsData = [
  {
    title: 'Misión',
    description: missiondc1 + missiondc2,
    propImg: MisionImg,
  },
  {
    title: 'Visión',
    description: visiondc1 + visiondc2,
    propImg: VisionImg,
  },
];

export default AboutUsData;
