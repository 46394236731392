const carouselItems = [
  {
    key: 1,
    title: 'Hacia un Futuro Inteligente',
    description: 'Como tu aliado en el avance hacia la inteligencia artificial te brindamos el conocimiento y las herramientas necesarias para que innoves y lideres en tu industria. Descubre cómo podemos ser tu socio estratégico en este emocionante viaje hacia el futuro.',
  },
  {
    key: 2,
    title: '¿Buscas aplicar la Inteligencia Artificial en tu negocio?',
    description: 'Transformamos tu visión de IA en realidad. Te ayudamos a mejorar la eficiencia, optimizar decisiones y crear experiencias únicas. Explora, implementa y maximiza el potencial de la IA con nuestra ayuda.',
  },
  {
    key: 3,
    title: '¿Necesitas a un experto en Inteligencia Artificial?',
    description: 'Nuestros especialistas en IA están preparados para impulsar tu proyecto. Con vasta experiencia, ofrecemos soluciones estratégicas, desarrollo, implementación y evaluación de modelos.',
  },
  {
    key: 4,
    title: 'IA para México y Latinoamérica',
    description: 'Diseñamos proyectos y modelos específicos para nuestra gente y cultura, buscando el progreso científico y tecnológico. Únete a nosotros y descubre nuestra visión y objetivos.',
  },
  {
    key: 5,
    title: 'IA Ética y Responsable',
    description: 'Creemos en una inteligencia artificial centrada en las personas. Promovemos una inteligencia artificial ética y responsable, liderando el camino hacia una IA que beneficie a todos. Conoce nuestros servicios de creación y revisión de normativas para asegurar que la IA beneficie a todos.',
  },
];

export default carouselItems;
