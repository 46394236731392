import React from 'react';
import './IndustryCard.css';

function IndustryCard({ id, title, icon, onCardClick }) {
  const handleClick = () => {
    onCardClick(id);
  };

  return (
    <div id={id} className="industryCard noSelect" onClick={handleClick}>
      <div className="industryCard__image">
        <img src={icon} alt="" draggable="false" />
      </div>
      <div className="industryCard__content">
        <h3 className="industryCard__title">
          {title}
        </h3>
      </div>
    </div>
  );
}

export default IndustryCard;
