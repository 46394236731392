import React from 'react';
import AOS from 'aos';
import { Link } from 'react-router-dom';
import './cta.css';
import AxolotlBottom from '../../assets/axolotlBottom.webp';
import 'aos/dist/aos.css';

AOS.init();

const CTA = () => (
  <div>
    <div className="triangleContainer">
      <div className="triangle" />
    </div>
    <div className="gpt3__cta">
      <div
        className="ctaAxolotlImg"
        data-aos="fade-right"
        data-aos-offset="100"
        data-aos-delay="50"
        data-aos-duration="700"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="true"
      >
        <img src={AxolotlBottom} alt="Aicraft Axolotl" />
      </div>
      <div className="ctaText">
        <h3
          className="miniTitle"
          data-aos="fade-zoom-in"
          data-aos-offset="100"
          data-aos-delay="200"
          data-aos-duration="700"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="true"
        >
          HABLEMOS
        </h3>
        <div
          style={{ maxWidth: '80%' }}
          data-aos="zoom-out"
          data-aos-offset="100"
          data-aos-delay="200"
          data-aos-duration="700"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="true"
        >
          <h2 className="title">Convierte Tus Datos En Avances</h2>
          <p className="ctadescription">
            Descubre cómo Aicraft puede proveerte las bases para tu futuro de innovación basada en datos e IA.
          </p>
        </div>
        <div
          className="gpt3__cta-btn"
          data-aos="fade-up"
          data-aos-offset="100"
          data-aos-delay="300"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="true"
        >
          <Link to="/contact">
            <button type="button">Contáctanos</button>
          </Link>
        </div>
      </div>
    </div>
    <input type="text" name="username" placeholder="Your username" tabIndex="-1" autoComplete="new-password" style={{ position: 'absolute', left: '-999999999px' }} />
  </div>
);

export default CTA;
