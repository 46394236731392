import React from 'react';
import AOS from 'aos';
import CarouselComponent from '../../../components/Carousel/Carousel';
import UseCaseCard from './cards/UseCaseCard';
import consultingServicesData from './ConsultingUseCasesData';
import './UseCase.css';
import 'aos/dist/aos.css';

AOS.init();

export default function UseCasesComponent() {
  return (
    <div className="useCasesSize">
      <div
        className="sectionTitle"
        data-aos="fade-zoom-in"
        data-aos-offset="100"
        data-aos-delay="300"
        data-aos-duration="700"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="true"
      >
        <span>
          Algunos
          <span className="gradientText" style={{ fontWeight: 'bold' }}>
            casos de uso
          </span>
        </span>
      </div>
      <CarouselComponent dotsEnabled={false}>
        {consultingServicesData.map((item) => (
          <UseCaseCard
            key={item.key}
            img={item.img}
            title={item.title}
            industry={item.industry}
            text={item.text}
          />
        ))}
      </CarouselComponent>
    </div>
  );
}
