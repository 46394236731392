import React from 'react';
import AOS from 'aos';
import { Link } from 'react-router-dom';
import './feature.css';
import 'aos/dist/aos.css';

AOS.init();

const Feature = ({ title, dir }) => (
  <div
    className="gpt3__features-container__feature"
    data-aos="fade-zoom-in"
    data-aos-offset="100"
    data-aos-delay="300"
    data-aos-duration="700"
    data-aos-easing="ease-in-out"
    data-aos-mirror="true"
    data-aos-once="true"
  >
    <Link to={`/${dir}`}>
      <div className="gpt3__features-container__feature-title">
        <div />
        <h1>{title}</h1>
      </div>
    </Link>
  </div>
);

export default Feature;
