import React from 'react';
import AOS from 'aos';
import { Link } from 'react-router-dom';
import './IndustriesCards.css';
import 'aos/dist/aos.css';

AOS.init();

function IndustriesCards({ mini, title, propImg }) {
  return (
    <div
      className={(propImg ? 'cardIndustries' : 'cardIndustries gradientBackground')}
      data-aos="fade-up"
      data-aos-offset="100"
      data-aos-delay="50"
      data-aos-duration="700"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="true"
    >
      {propImg && <img src={propImg} alt="Industries Card" />}
      <div className="industriesCardContent">
        <h2 className="industriesCardTitle">{mini}</h2>
        <h3 className="industriesCardminiTitle">{title}</h3>
        {!propImg && <Link to="/industries"><button type="button" className="cardCallToAction">Comenzar</button></Link>}
      </div>
      <input type="text" name="username" placeholder="Your username" tabIndex="-1" autoComplete="new-password" style={{ position: 'absolute', left: '-999999999px' }} />
    </div>
  );
}

export default IndustriesCards;
