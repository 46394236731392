import banca from '../../../assets/consulting/useCases/banca.jpeg';
import salud from '../../../assets/consulting/useCases/salud.jpeg';
import atencionCliente from '../../../assets/consulting/useCases/atencionCliente.jpeg';
import energia from '../../../assets/consulting/useCases/energia.jpeg';
import facial from '../../../assets/consulting/useCases/facial.jpeg';
import educacion from '../../../assets/consulting/useCases/educacion.jpeg';

const consultingServicesData = [
  {
    key: 1,
    img: banca,
    industry: 'Finanzas y banca',
    title: 'Detección de fraudes',
    text: 'Implementación de IA para monitorear transacciones en tiempo real y detectar patrones sospechosos que puedan indicar fraude, permitiendo que las instituciones financieras actúen rápidamente para prevenir pérdidas y proteger los activos de los clientes.',
  },
  {
    key: 2,
    img: salud,
    industry: 'Salud',
    title: 'Diagnóstico predictivo',
    text: 'Uso de machine learning para predecir el riesgo de enfermedades en pacientes basándose en su historial médico y otros datos relevantes, para facilitarla detección temprana y la intervención proactiva.',
  },
  {
    key: 3,
    img: atencionCliente,
    industry: 'Atención al cliente',
    title: 'Chatbots',
    text: 'Implementación de chatbots impulsados por IA para automatizar la atención al cliente en plataformas digitales, o redirigir casos más complejos a un agente humano, mejorando la eficiencia operativa y la satisfacción del cliente.',
  },
  {
    key: 4,
    img: energia,
    industry: 'Energético',
    title: 'Gestión de energía',
    text: 'Implementación de sistemas de BI y analítica avanzada para monitorear y gestionar el consumo de energía en tiempo real, permitiendo optimizar el uso de recursos, reducir costos operativos y minimizar el impacto ambiental.',
  },
  {
    key: 5,
    img: facial,
    industry: 'Variadas',
    title: 'Reconocimiento facial',
    text: 'Mejora la seguridad en instalaciones sensibles mediante una verificación rápida y precisa de identidad, reduciendo accesos no autorizados y eliminando la necesidad de tarjetas o contraseñas.',
  },
  {
    key: 6,
    img: educacion,
    industry: 'Educación',
    title: 'Generación de exámenes',
    text: 'Generación automática de exámenes basadas en contenidos vistos durante tus cursos educativos.',
  },
  {
    key: 7,
    img: '',
    industry: '',
    title: '¿Quieres ver más?',
    text: '',
  },
];

export default consultingServicesData;
