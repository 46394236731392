import React from 'react';
import { useRouteError, Link } from 'react-router-dom';
import './error-page.css';

export default function ErrorPage() {
  const error = useRouteError();

  return (
    <div id="error-page">
      <h1>¡Ups!</h1>
      <p>Disculpa, ocurrió un error inesperado.</p>
      <p>
        <i>{error}</i>
      </p>
      <p>
        <Link to="/" style={{ textDecoration: 'underline', padding: '5px', color: '#81AFDD' }}>
          Volver al inicio
        </Link>
      </p>
    </div>
  );
}
