import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { notification, Spin, Input } from 'antd';
import emailjs from '@emailjs/browser';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Contact.css';
// import ReCAPTCHA from 'react-google-recaptcha';

const { TextArea } = Input;

AOS.init();

export default function Contact() {
  const location = useLocation();
  const industry = location?.state?.industry;
  const industryText = industry === undefined ? '' : `Interés sobre ${industry}`;

  const nameRegex = /^[a-zA-Z\sñÑáÁéÉíÍóÓúÚ]+$/;
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const form = useRef();

  const [spinning, setSpinning] = React.useState(false);
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type, title, message) => {
    api[type]({
      message: title,
      description: message,
      duration: 4,
      showProgress: true,
    });
  };

  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactText, setContactText] = useState(industryText);

  const [validName, setValidName] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  // const [validProject, setValidProject] = useState(true);

  function contactNameHandler(e) {
    const { value } = e.target;

    setContactName(value);

    if (e.target.value.length >= 1) {
      if (!e.target.value.match(nameRegex)) {
        e.preventDefault();
        setValidName(false);
      } else {
        setValidName(true);
      }
    } else {
      setValidName(true);
    }
  }
  function contactEmailHandler(e) {
    const { value } = e.target;
    setContactEmail(value);
    if (e.target.value.length >= 1) {
      if (!e.target.value.match(emailRegex)) {
        e.preventDefault();
        setValidEmail(false);
      } else {
        setValidEmail(true);
      }
    } else {
      setValidEmail(true);
    }
  }
  function contactTextHandler(e) {
    setContactText(e.target.value);
  }

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_aicraftOutlook', 'template_l5o35pv', form.current, {
        publicKey: 'n2kSvMLVJIt5v4QLD',
      })
      .then(
        () => {
          setSpinning(false);
          openNotificationWithIcon(
            'success',
            '¡Enviado!',
            'Gracias por contactarnos, pronto nos comunicaremos contigo.',
          );
        },
        () => {
          setSpinning(false);
          openNotificationWithIcon(
            'error',
            '¡Oh no!',
            'Ocurrió un error al enviar tu mensaje. Por favor inténtalo más tarde',
          );
        },
      );
  };

  function sendMail(e) {
    e.preventDefault();
    setSpinning(true);

    const contactArray = [contactName, contactEmail, contactText];

    const emptyVerify = contactArray.some((item) => item.trim() === '');
    if (emptyVerify) {
      setSpinning(false);
      openNotificationWithIcon(
        'error',
        'Campos incompletos',
        'Completa todos los campos para continuar',
      );
    } else {
      if (!validName || !validEmail) {
        setSpinning(false);
        openNotificationWithIcon(
          'error',
          'Campos no válidos',
          'Verifica la información de tus campos e inténtalo de nuevo',
        );
        return;
      }
      sendEmail(e);
    }
  }

  return (
    <>
      {contextHolder}
      <div className="sectionNavMargin">
        <div className="section__padding">
          <div className="contactTitleContainer">
            <span
              className="contactTitle"
              data-aos="fade-up"
              data-aos-offset="0"
              data-aos-delay="100"
              data-aos-duration="700"
              data-aos-easing="ease-out-sine"
              data-aos-mirror="true"
              data-aos-once="true"
            >
              Enfrenta los desafíos del presente y construye un futuro más
              <span className="gradientText">innovador y competitivo</span>
            </span>
            <span
              className="contactSubtitle"
              data-aos="fade-left"
              data-aos-offset="0"
              data-aos-delay="300"
              data-aos-duration="700"
              data-aos-easing="ease-out-sine"
              data-aos-mirror="true"
              data-aos-once="true"
            >
              Solo cuéntanos sobre tu proyecto y nosotros te contactaremos.
            </span>
          </div>
          <div
            className="formRadius"
            data-aos="fade-up"
            data-aos-offset="0"
            data-aos-delay="400"
            data-aos-duration="700"
            data-aos-easing="ease-out-sine"
            data-aos-mirror="true"
            data-aos-once="true"
          >
            <div className="formContainer">
              <form ref={form} onSubmit={sendMail}>
                <div className="nameContainer">
                  <span>Tu nombre</span>
                  <Input
                    type="text"
                    name="from_name"
                    id="from_name"
                    onChange={(e) => contactNameHandler(e)}
                    status={!validName && 'error'}
                  />
                  {!validName && <span className="errorMessage">Ingresa tu nombre únicamente usando letras y espacios.</span>}
                </div>
                <div className="emailContainer">
                  <span>Tu correo</span>
                  <Input
                    type="email"
                    name="from_email"
                    id="from_email"
                    onChange={(e) => contactEmailHandler(e)}
                    status={!validEmail && 'error'}
                  />
                  {!validEmail && <span className="errorMessage">Ingresa un correo válido.</span>}
                </div>
                <div className="projectContainer">
                  <span>Sobre tu proyecto</span>
                  <TextArea
                    name="message"
                    id="message"
                    value={contactText}
                    onChange={(e) => contactTextHandler(e)}
                  />
                </div>
                {/* <ReCAPTCHA size="normal" sitekey="6LdnhUMqAAAAAA8Z3_unlPOSOCaPRXQfkTyuwGiI" /> */}
                <div className="submitContainer">
                  <input type="submit" value="Enviar" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Spin spinning={spinning} fullscreen />
    </>
  );
}
