import React from 'react';
import { Link } from 'react-router-dom';
import aicraftLogo from '../../assets/logos/aicraftLogoWhite.svg';
import './footer.css';

const Footer = () => (
  <div className="gpt3__footer">
    <div className="gpt3__footer-links_logo">
      <img src={aicraftLogo} alt="aicraft logo" />
    </div>
    <div className="divider" />
    <div className="gpt3__footer-Content">
      <Link to="/contact">
        <h2>Contacto</h2>
      </Link>
      <p>info@aicraft.mx</p>
      {/* <p>Puebla, México</p> */}
    </div>
  </div>
);

export default Footer;
