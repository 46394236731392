import React from 'react';
import AOS from 'aos';
import './industries.css';
import '../possibility/possibility.css';
import dividerImg from '../../../assets/Divider.svg';
import IndustriesCards from './cards/IndustriesCards.jsx';
import IndustriesCardData from './IndustriesCards.js';
import 'aos/dist/aos.css';

AOS.init();

const Industries = () => (
  <div>
    <div className="svgTriangleContainer">
      <img src={dividerImg} />
    </div>

    <div className="gpt3__possibility" id="possibility">
      <div className="gpt3__possibility-content">
        <h3
          className="miniTitle"
          data-aos="fade-zoom-in"
          data-aos-offset="100"
          data-aos-delay="50"
          data-aos-duration="700"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="true"
        >
          EMPODERANDO CLIENTES
        </h3>
        <div
          data-aos="fade-up"
          data-aos-offset="100"
          data-aos-delay="50"
          data-aos-duration="700"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="true"
        >
          <h2 className="title">Estamos abriendo terreno para la IA</h2>
          <p className="description">
            Aicraft impulsa aplicaciones de tecnología innovadoras en una amplia gama de industrias.
          </p>
        </div>
        <div className="productCardsWrapper">
          {IndustriesCardData.map((item, key) => (
            <IndustriesCards
              key={key}
              mini={item.mini}
              title={item.title}
              propImg={item.image}
            />
          ))}
        </div>
        {/* <div className="ctaButtonBg">
          <button type="button" className="featureBtns">Ver Soluciones</button>
        </div> */}
      </div>
    </div>
  </div>
);

export default Industries;
