import React from 'react';
import './Aboutus.css';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { CTA } from '../../components';
import TextContainer from './textContainers/TextContainer';
import AicraftLogo from '../../assets/logos/aicraftLogoBlueWhite.svg';
import AboutUsData from './AboutUsData.js';

function Aboutus() {
  return (
    <div className="AboutUsContainer">
      <div className="floatingBackButton">
        <Link to="/">
          <ArrowLeftOutlined /> Volver al Inicio
        </Link>
      </div>
      <div className="fadeIn fgContainer">
        <div className="floatingGradient" />
      </div>
      <div className="heroContainer">
        <span className="heroTitle">
          <span className="heroQuien">¿Quiénes</span>
          <br />
          <span className="heroSomos">somos?</span>
        </span>
        <span className="heroText">
          Nacida de la búsqueda por el progreso científico y su aplicación en
          México y Latinoamérica para romper las barreras que enfrentan las
          compañías e instituciones en nuestra región para implementar la IA en
          las diversas industrias.
        </span>
      </div>
      <div className="introContainer textContainer">
        <img src={AicraftLogo} alt="Aicraft Logo" />
        <p className="introTitle">Nuestro Origen</p>
        <span>
          En los últimos años, la Inteligencia Artificial (IA) ha experimentado
          un crecimiento sin precedentes y se ha adoptado ampliamente en
          diversos sectores a nivel mundial, transformando operaciones,
          decisiones e interacción con clientes. Sin embargo, en México y
          Latinoamérica, la oferta de servicios especializados en IA es
          limitada, obligando a las empresas a desarrollar internamente sus
          capacidades o recurrir a proveedores no especializados, lo que
          dificulta una implementación efectiva de IA.
          <br />
          <br />
          En respuesta a esta necesidad, surge Aicraft, una empresa mexicana
          especializada en IA, Ciencia de Datos y Business Intelligence. Aicraft
          busca impulsar el desarrollo e innovación en la región, conectando
          disciplinas, sectores y comunidades para fomentar el progreso en
          ciencia, industria y sociedad.
        </span>
      </div>

      {AboutUsData.map((item, key) => (
        <TextContainer
          key={key}
          title={item.title}
          description={item.description}
          propImg={item.propImg}
        />
      ))}
      <CTA />
    </div>
  );
}

export default Aboutus;
